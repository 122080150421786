import React, { Component } from 'react';
import { Link, Location } from "@reach/router";
import PropTypes from 'prop-types';

import './BaseMultipleRouteNavigationItem.scss';

class BaseMultipleRouteNavigationItem extends Component {

  renderComponent = () => {
    let view = null;
    const routes = this.props.routes || [];

    if (routes.length !== 0) {
      view = routes.map(item => {
        if (item.url) {
          return (
            <Link
              to={item.url}
              key={item.text}
              className='Breadcrumb-link'
            >
              {item.text}
            </Link>
          )
        } else {
          return (
            <span
              key={item.text}
              className='Breadcrumb-text'
            >
              {item.text}
            </span>
          )
        }
      })
    }

    return <div className='BaseMultipleRouteNavigationItem'>{view}</div>;
  };

  render() {
    return (
      <Location>{this.renderComponent}</Location>
    );
  }
}

BaseMultipleRouteNavigationItem.propTypes = {
  routes: PropTypes.array.isRequired
};

export default BaseMultipleRouteNavigationItem;
