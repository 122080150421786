import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import moment from 'moment';

import { momentFormats, renderMoment } from "../../../../../../../../../services/date-service/date.service";
import {
  getRoyaltiesMoviesPeriodRoute
} from '../../../../../../../../../services/navigation/royalties-navigation/royalties-navigation-routes.service';

import LinkButton from "../../../../../../../../../components/LinkButton/LinkButton";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import RoyaltiesDisplayBaseAbstract from "../RoyaltiesDisplayBaseAbstract/RoyaltiesDisplayBaseAbstract";

import BaseFooter from '../../../../../../../../../components/BaseFooter/BaseFooter';
import { exportRoyaltiesByMonth, getRoyaltiesReportMonthly } from '../../../../../../../../../services/royalties-service/royalties.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';
import { CSVLink } from 'react-csv';
import RoyaltiesHeader from '../RoyaltiesHeader/RoyaltiesHeader';
import { columnWidth } from '../../../../../../../../../services/util-service/util.service';

class RoyaltiesByMonth extends RoyaltiesDisplayBaseAbstract {
  state = {
    data: [],
    dataLoaded: false,
    loadingMsg: 'Loading...',
    pagination: {},
    period: {},
    range: []
  };

  constructor(props) {
    super(props);
    const { period, range } = this.getInitialPeriod();
    this.state.period = period;
    this.state.range = range;
  }

  getInitialPeriod = () => {
    const { pathname } = window.location;
    let rangeStart;
    let rangeEnd;
    if (pathname === '/main') {// We are on main Royalties route so load current year Royalties
      rangeStart = moment().startOf('year');
      rangeEnd = moment().endOf('month');
      const date = {
        start_date: rangeStart.format(this.dateFormat),
        end_date: rangeEnd.format(this.dateFormat)
      };
      return {
        period: date,
        range: [rangeStart, rangeEnd]
      };
    }
    return {};
  };

  getMonthData = (start_date, end_date) => {
    start_date = start_date.clone().startOf('month');
    end_date = end_date.clone().endOf('month');

    this.setState({
      period: {
        start_date: start_date.format(momentFormats.dateYearFirst),
        end_date: end_date.format(momentFormats.dateYearFirst)
      },
      range: [start_date, end_date]
    }, this.fetchRoyaltiesByMonth.bind(this, 1))
  };

  componentDidMount() {
    const [start_date, end_date] = this.state.range;
    this.getMonthData(start_date, end_date, 1);
  };

  compensateForValueIfMissing = (value, valueText) => {
    return value ? valueText : '';
  };

  getColumns = () => {
    const { t } = this.props;

    return [{
      dataIndex: 'start_date',
      title: t('PayoutsByMonth.startDate'),
      render: renderMoment,
      width: columnWidth(180)
    }, {
      dataIndex: 'end_date',
      title: t('PayoutsByMonth.endDate'),
      render: renderMoment,
      width: columnWidth(180)
    }, {
      dataIndex: 'status',
      title: 'Status',
      render: this.renderStatus,
      width: columnWidth(200)
    }, {
      dataIndex: 'view_time',
      title: t('PayoutsByMonth.viewTime'),
      render: this.renderTime,
      width: columnWidth(250)
    }, {
      dataIndex: 'payout',
      title: t('PayoutsByMonth.payout'),
      render: this.renderNumber,
      width: columnWidth(150),
      align: 'right'
    }, {
      title: t('PayoutsByMonth.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem,
      width: columnWidth(150)
    }];
  };

  getListCardFooter = (item) => {
    return (
      <div className="RoyaltiesByMonth-listCard-footer">
        {this.renderActionItem(item)}
      </div>
    );
  };

  getReductionHeight = () => 160;

  getRowKey = (item) => item.start_date;

  onEditPayoutStatus = (startDate, endDate, status) => {
    const { onEditPayoutStatus } = this.props;
    onEditPayoutStatus(startDate, endDate, status);
  };

  renderActionItem = item => {
    const { t } = this.props;
    const { start_date } = item;
    const year = moment(start_date).year();
    const month = moment(start_date).month() + 1;
    return (
      <LinkButton href={getRoyaltiesMoviesPeriodRoute(year, month)}>
        {t('PayoutsByMonth.viewDetails')}
      </LinkButton>
    );
  };

  onDateChange = (start_date, end_date) => {
    this.getMonthData(start_date, end_date);
  }

  renderHeader = () => {
    const { start_date } = this.state.period;
    const year = moment(start_date).year();
    const month = moment(start_date).month() + 1;
    return (
      <RoyaltiesHeader
        month={month}
        year={year}
        period={this.state.period}
        range={this.state.range}
        onDateChange={this.onDateChange}
      />
    );
  };

  renderTime = time => {
    const { t } = this.props;
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time / (60 * 60)) % 24);
    const minutes = Math.floor((time / 60) % 60);

    const daysText = `${days} ${t('PayoutsByMonth.days')}`;
    const hoursText = `${hours} ${t('PayoutsByMonth.hours')}`;
    const minutesText = `${minutes} ${t('PayoutsByMonth.minutes')}`;

    return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
  };

  onExportMonthSuccess = response => {
    this.setState({
      loadingMsg: ''
    });
    displaySuccessNotification({
      duration: 0,
      message: 'Export successfull!',
      description: (
        <CSVLink
          data={response.data}
          className="btn btn-primary"
          target="_blank"
          filename={"royalties-report-month.csv"}
        >Download Month Report</CSVLink>
      )
    });
  };

  downloadCsvFile = () => {
    const { period } = this.state;
    this.setState({
      loadingMsg: 'Export in progress'
    });
    exportRoyaltiesByMonth(period.start_date, period.end_date)
      .then(this.onExportMonthSuccess)
      .catch(err => {
        console.log(err);
        this.setState({
          loadingMsg: ''
        });
        displayErrorNotification({
          duration: 3,
          message: 'Export failed!'
        });
      })
  };

  loadMonthsData = (response) => {
    const { monthly, pagination } = response.data.data;
    const { current_page, last_page, total, per_page } = pagination;

    this.setState({
      rows: monthly || [],
      pagination: {
        page: current_page,
        pageCount: last_page,
        perPage: per_page,
        recordCount: total
      },
      dataLoaded: true,
      loadingMsg: ''
    });
  };

  fetchRoyaltiesByMonth = (page) => {
    const { start_date, end_date } = this.state.period;
    const message = this.props.t('PayoutsView.loadingMonthsPleaseWait');
    this.setState({
      loadingMsg: message
    });

    getRoyaltiesReportMonthly(start_date, end_date, page)
      .then(this.loadMonthsData)
      .catch((err = {}) => {
        displayErrorNotification({
          duration: 6,
          message: err.message,
          description: err.response?.data?.error || err.response?.message
        });
        this.setState({
          dataLoaded: true,
          loadingMsg: ''
        });
        console.log(err);
      });
  };

  onPageChange = (page) => this.fetchRoyaltiesByMonth(page);

  renderFooter = () => {
    let view = null;
    const { page, perPage, recordCount, pageCount } = this.state.pagination;
    view = (
      <BaseFooter
        className='Footer'
        currentPage={page}
        onChange={this.onPageChange}
        pageCount={pageCount}
        pageSize={perPage}
        recordCount={recordCount}
      >
        <Button onClick={() => this.downloadCsvFile()}>Export</Button>
      </BaseFooter>
    );
    return view;
  };

  render() {
    const { dataLoaded, loadingMsg, rows } = this.state;

    return (
      <LoadingWrapper
        dataLoaded={dataLoaded}
        isLoading={!!loadingMsg}
        loadingMsg={loadingMsg}
      >
        {this.renderHeader()}
        {this.renderTable(rows)}
        {this.renderFooter()}
      </LoadingWrapper>
    );
  };
}

export default withTranslation()(RoyaltiesByMonth);