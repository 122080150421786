import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { momentFormats } from '../../../../../../../../../services/date-service/date.service';
import './RoyaltiesHeader.scss';

class RoyaltiesHeader extends Component {
    dataFrom = moment('2016-09-30', momentFormats.dateYearFirst).valueOf();
    state = {
        endDate: this.props.range[1],
        startDate: this.props.range[0]
    }

    constructor(props) {
        super(props);
        this.state.startDate = props.range[0];
        this.state.endDate = props.range[1];
    }

    applyFilter = () => {
        const { startDate, endDate } = this.state;
        this.props.onDateChange(startDate, endDate);
    };

    disabledEndDate = endValue => {
        const { startDate } = this.state;
        return !!(startDate && endValue && (endValue.valueOf() <= startDate.valueOf())) ||
            (endValue && endValue > moment().endOf('month'));
    };

    disabledStartDate = (startValue) => {
        const { endDate } = this.state;
        return !!(startValue && endDate && (startValue.valueOf() < this.dataFrom || startValue.valueOf() > endDate.valueOf())) ||
            (startValue && (startValue > moment().endOf('month') || startValue.valueOf() < this.dataFrom));
    };

    handleChange = (picker, date) => {
        this.setState({
            [picker]: date
        });
    };

    renderStartDate = () => {
        const { t } = this.props;
        const { startDate } = this.state;
        return this.renderMonthPickerField(
            t(`PayoutsByMonthHeader.start`), startDate, 'startPicker',
            this.disabledStartDate, this.handleChange.bind(this, 'startDate')
        );
    };

    renderEndDate = () => {
        const { t } = this.props;
        const { endDate } = this.state;
        return this.renderMonthPickerField(
            t(`PayoutsByMonthHeader.end`), endDate, 'endPicker',
            this.disabledEndDate, this.handleChange.bind(this, 'endDate')
        );
    };

    renderMonthPickerField = (label, value, name, disabledDateFn, onChange) => {
        return (
            <div className="MonthPickerField">
                <span className="Label">{label}: </span>
                <DatePicker.MonthPicker
                    allowClear={false}
                    name={name}
                    disabledDate={disabledDateFn}
                    value={value}
                    onChange={onChange} />
            </div>
        );
    };

    render() {
        const { t } = this.props;

        return (
            <div className="Header">
                {this.renderStartDate()}
                {this.renderEndDate()}
                <Button onClick={this.applyFilter}>{t(`PayoutsByMonthHeader.applyFilter`)}</Button>
            </div>
        );
    }
}

export default withTranslation()(RoyaltiesHeader);
