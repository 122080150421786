import React, { Component } from 'react';
import { Layout } from "antd";

import MainContent from "./components/MainContent/MainContent";

import './MainView.scss';

class MainView extends Component {
  state = {
    contentHeight: undefined,
    sideMenuCollapsed: false
  };

  componentDidMount() {
    const view = document.getElementsByClassName('MainDashboardMainView')[0];
    if (view) {
      this.setState({
        contentHeight: view.clientHeight
      });
    }
  }

  setSideMenuCollapsed = (collapsed) => {
    this.setState({
      sideMenuCollapsed: collapsed
    });
  };

  render() {

    return (
      <Layout className="MainDashboardMainView">
        {/* <SideMenu collapsed={this.state.sideMenuCollapsed}
                  navigationItems={[] || this.props.navigationItems}
                  /> */}
        <MainContent contentHeight={this.state.contentHeight} />
      </Layout>
    );
  }
}

MainView.propTypes = {

};

export default MainView;

