import { ROUTES } from "../../../routes";
import { MAIN_DASHBOARD_VIEW_ROUTES } from "../../../pages/MainDashboard/components/MainView/main-dashboard-view.routes";

/**
 * Returns Royaltiess view route
 *
 * @returns {string}
 */
export const getRoyaltiesRoute = () => {
  return `/${ROUTES.MAIN_DASHBOARD}/${MAIN_DASHBOARD_VIEW_ROUTES.ROYALTIES}`;
};

/**
 * Returns Royalties route
 *
 * @param {string|number} year
 * @param {string|number} month
 *
 * @returns {string}
 */
export const getRoyaltiesMoviesPeriodRoute = (year, month) => {
  return `${getRoyaltiesRoute()}/movies/period/${year}/${month}`;
};

export const getRoyaltiesStudiosPeriodRoute = (year, month) => {
  return `${getRoyaltiesRoute()}/studios/period/${year}/${month}`;
};

export const getRoyaltiesStudioMoviesRoute = (id) => {
  return `${getRoyaltiesRoute()}/studio/${id}/movies`;
};

// /**
//  * Returns Royalties property route
//  *
//  * @param {string|number} propertyId
//  * @param {string|number} year
//  * @param {string|number} month
//  *
//  * @returns {string}
//  */
// export const getRoyaltiesPropertyRoute = (propertyId, year, month) => {
//   return `${getRoyaltiesPeriodRoute(year, month)}/properties/${propertyId}`;
// };

// /**
//  * Returns Royalties route
//  *
//  * @param {string|number} licensorId
//  *
//  * @returns {string}
//  */
// export const getRoyaltiessLicensorRoute = (propertyId, licensorId, year, month) => {
//   return `${getRoyaltiesPropertyRoute(propertyId, year, month)}/licensors/${licensorId}`;
// };