import moment from 'moment';

export const momentFormats = {
  date: 'MMMM Do YYYY',
  dateHours: 'MMMM Do YYYY, h:mm a',
  dateMonthFirst: 'MM/DD/YYYY',
  dateYearFirst: 'YYYY-MM-DD',
  dateYearFirstTime: 'YYYY-MM-DD HH:mm:ss',
  timeFormat: 'h:mm a'
};

export const convertMomentToDate = (time, addTime, UTC) => {
  const format = addTime ? momentFormats.dateYearFirstTime : momentFormats.dateYearFirst;
  let date = moment(time);
  if (UTC) {
    date = date.utc();
  }
  return date.format(format);
};

export const convertRunTimeToSeconds = (runTime) => {
  return moment.duration(runTime).asSeconds();
};

export const convertToMoment = (time, showTime, local) => {
  let date = null;
  if (time) {
    if (local) {
      date = moment(time);
    } else {
      const format = showTime ? momentFormats.dateYearFirstTime : momentFormats.dateMonthFirst;
      date = moment(time, format);
    }
  }

  return date;
};

export const formatYYYYMMDD = (time, showTime) => {
  const format = showTime ? momentFormats.dateYearFirstTime : momentFormats.dateYearFirst;
  return moment(time, format);
};

/* istanbul ignore next */
export const renderMomentFormattedTime = (date) => {
  return date ? date.format(momentFormats.timeFormat) : '';
};

export const renderMomentWithHours = date => {
  return date ? moment(date).format(momentFormats.dateHours) : '';
};

export const renderMoment = (date, format) => {
  if (!format || typeof format !== 'string') {
    format = momentFormats.date;
  }
  return date ? moment(date).format(format) : '';
};

export const renderMomentFormatted = (date, format) => {
  return date ? date.format(format) : '';
};

export const isDatePassed = (date) => {
  const now = moment();
  const dateToCompare = moment(date, momentFormats.dateMonthFirst);

  return now.valueOf() > dateToCompare.valueOf();
};

/* istanbul ignore next */
export const getMoment = (year, month, day, hour, minute) => {
  const date = moment();
  return date.year(year).month(month).date(day).hour(hour).minute(minute);
};

/* istanbul ignore next */
export const isDateBellow = (date1, time1, date2, time2) => {
  return checkDate(date1, time1, date2, time2, 'before');
};

/* istanbul ignore next */
export const checkDate = (date1, time1, date2, time2, direction) => {
  // Date1 and time1 split
  const year1 = date1.year();
  const month1 = date1.month();
  const day1 = date1.date();
  const hour1 = time1.hour();
  const minute1 = time1.minute();
  // Date1 and time2 split
  const year2 = date2.year();
  const month2 = date2.month();
  const day2 = date2.date();
  const hour2 = time2.hour();
  const minute2 = time2.minute();
  const calcTime1 = getMoment(year1, month1, day1, hour1, minute1);
  const calcTime2 = getMoment(year2, month2, day2, hour2, minute2);
  return direction === 'before' ? calcTime1 < calcTime2 : calcTime1 > calcTime2;
};

/* istanbul ignore next */
export const isDateInBetween = (date, time, startDate, startTime, endDate, endTime) => {
  let isInBetween = false;
  if (date && time && startDate && startTime && endDate && endTime) {
    isInBetween = !isDateBellow(date, time, startDate, startTime) && !isDateAbove(date, time, endDate, endTime);
  }

  return isInBetween;
};

/* istanbul ignore next */
export const isDateAbove = (date1, time1, date2, time2) => {
  return checkDate(date1, time1, date2, time2, 'after');
};
