import React from 'react';
import { Layout } from 'antd';

// import BannersViewSection from './components/BannersView/BannersViewSection';
// import CategoriesView from './components/CategoriesView/CategoriesView';
// import LicensorsView from './components/LicensorsView/LicensorsView';
// import MailView from './components/MailView/MailView';
// import MFIDsView from './components/MFIDsView/MFIDsView';
// import MoviesView from './components/MoviesView/MoviesView';
// import PayoutsView from './components/PayoutsView/PayoutsView';
// import PropertiesView from './components/PropertiesView/PropertiesView';
// import SexActMovies from './components/MoviesView/SexActMovies/SexActMovies';
// import StarsView from './components/StarsView/StarsView';
// import StudiosView from './components/StudiosView/StudiosView';
// import UsersView from './components/UsersView/UsersView';

// import NotFound from '../../../../../NotFound/NotFound';

import RoyaltiesView from './components/RoyaltiesView/RoyaltiesView';

import './MainContent.scss';
import { MainDashboardComController } from '../../../../main-dashboard-comm.controller';

export const hideSideMenu = () => {
  MainDashboardComController.hideSideMenuIfVisible();
};

const MainContent = props => {
  return (
    <Layout>
      <Layout.Content className='MainContent' onClick={hideSideMenu}>
        <RoyaltiesView contentHeight={props.contentHeight} />
      </Layout.Content>
    </Layout>
  );
};

export default MainContent;
