import React, { Component } from 'react';
import { List, Table } from "antd";
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';

import { getScrollHeight, isWeb } from "../../../../../../../../../services/util-service/util.service";

class RoyaltiesDisplayBaseAbstract extends Component {
  rowHeight = 54;

  calcTableHeight = (data) => {
    let tableHeight;
    const { contentHeight } = this.props;
    if (data) {
      tableHeight = getScrollHeight(contentHeight, data, this.rowHeight, this.getReductionHeight());
    }
    return tableHeight;
  };

  getColumns = () => [];

  getReductionHeight = () => 0;

  getRowKey = item => '';

  renderList = (data, renderItem) => {
    return (
      <List dataSource={data}
        renderItem={renderItem}
        split={false}
        itemLayout='vertical' />
    );
  };

  renderListItemField = (label, value) => {
    return (
      <p>
        <span className='ListItemLabel'>{label}:</span>
        {value}
      </p>
    );
  };

  renderNumber = (item) => {
    return (
      <NumberFormat
        value={item}
        displayType={'text'}
        thousandSeparator={true} />
    );
  };

  renderStatus = status => {

    let txtStatus;

    switch (status) {
      case '0':
        txtStatus = 'Estimated';
        break;
      case '1':
        txtStatus = 'Pending Approval';
        break;
      case '2':
        txtStatus = 'Final';
        break;
      default:
        txtStatus = '';
    }

    return txtStatus;
  }

  renderTable = (data) => {
    const tableHeight = this.calcTableHeight(data);
    const props = {
      dataSource: data,
      rowKey: this.getRowKey,
      bordered: true,
      columns: this.getColumns(),
      pagination: false
    };
    if (isWeb()) {
      props.scroll = tableHeight;
    }
    if (!isWeb()) {
      props.size = 'small';
    }
    return (
      <Table {...props} />
    );
  };

  render() {
    return (
      <div />
    );
  }
}

RoyaltiesDisplayBaseAbstract.propTypes = {
  contentHeight: PropTypes.number
};

export default RoyaltiesDisplayBaseAbstract;
