import React, { Suspense } from 'react';
import { Router } from "@reach/router";

import './App.scss';
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import MainDashboard from "./pages/MainDashboard/MainDashboard";
import { ROUTES } from "./routes";
import ModalContainer from "./components/ModalContainer/ModalContainer";
import LoadingMask from "./components/LoadingMask/LoadingMask";
import 'antd/dist/antd.css';
import { cleanupToken, hasTokenExpired } from './services/token-service/token.service';

function App() {
  if (hasTokenExpired()) {
    cleanupToken();
  }
  return (
    <div className='App'>
      <Suspense fallback={<div className="SuspenseLoader">Loading...</div>}>
        <Router>
          <Login path={ROUTES.ROOT} />
          <MainDashboard path={`${ROUTES.MAIN_DASHBOARD}/*`} />
          <NotFound default />
        </Router>
      </Suspense>
      <ModalContainer />
      <LoadingMask />
    </div>
  );
}

export default App;
