import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Layout } from 'antd';
import { LoadingController } from '../../controllers/loading-controller/loading.controller';

// import ConfirmationModal from "../../components/modal/ConfirmationModal/ConfirmationModal";
import MainDashboardHeader from './components/MainDashboardHeader/MainDashboardHeader';
import MainView from './components/MainView/MainView';
import { authLogout } from '../../services/auth-service/auth.service';
import { cleanupToken } from '../../services/token-service/token.service';
import { clearCache } from '../../services/cache-service/cache.service';
import { showNavigationRoot } from "../../services/navigation/navigation.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../services/notification-service/notification.service";

import { removeUserLocalData } from "../../services/users-service/users.service";
import './MainDashboard.scss';

class MainDashboard extends Component {
  /**
   * Cleans up any user data on logout
   *
   * @returns {Promise<void>}
   */
  cleanup = async () => {
    cleanupToken();
    removeUserLocalData();
    await this.navigateToRoot();
    LoadingController.removeLoadingMask();
  };

  clearCache = () => {
    const { t } = this.props;
    LoadingController.showLoadingMask(t('MainDashboard.clearingCache'));
    clearCache()
      .then(this.onClearCacheSuccess)
      .catch(this.onClearCacheFail);
  };

  onClearCacheFail = () => {
    LoadingController.removeLoadingMask();
    displayErrorNotification({
      duration: 3,
      message: this.props.t('MainDashboard.cacheClearedFailure')
    });
  };

  onClearCacheSuccess = () => {
    LoadingController.removeLoadingMask();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('MainDashboard.cacheClearedSuccessfully')
    });
  };

  /**
   * Handles logout error
   *
   * @param {Object} e
   */
  handleLogoutError = (e) => {
    LoadingController.removeLoadingMask();
  };

  /**
   * Handles logout success and cleans up user data
   *
   * @param {Object} response
   */
  handleLogoutSuccess = (response) => {
    if (response.status === 204) {
      this.cleanup();
    }
  };
  /**
   * Triggers user logout request
   *
   * @returns {Promise<void>}
   */
  logoutUser = async () => {
    const { t } = this.props;
    LoadingController.showLoadingMask(t('MainDashboard.loggingOut'));
    try {
      const response = await authLogout();
      this.handleLogoutSuccess(response);
    } catch (e) {
      this.handleLogoutError(e);
    }
  };

  /**
   * Navigates to root route
   *
   * @returns {Promise<void>}
   */
  async navigateToRoot() {
    await showNavigationRoot();
  }

  render() {
    return (
      <Layout className="MainDashboard">
        <div className="MainDashboard-inner">
          <MainDashboardHeader logout={this.logoutUser} />
          <MainView />
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(MainDashboard);
