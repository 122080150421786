import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import moment from 'moment';
import BaseMultipleRouteNavigationItem from '../../../../../../../../../components/BaseMultipleRouteNavigationItem/BaseMultipleRouteNavigationItem';

import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import RoyaltiesDisplayBaseAbstract from "../RoyaltiesDisplayBaseAbstract/RoyaltiesDisplayBaseAbstract";
import { exportRoyaltiesByStudio, getRoyaltiesByStudio } from '../../../../../../../../../services/royalties-service/royalties.service';
import { CSVLink } from 'react-csv';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';
import { momentFormats, renderMoment } from '../../../../../../../../../services/date-service/date.service';
import { ROUTES } from '../../../../../../../../../routes';
import { Link } from '@reach/router';
import Tabs from '../../Tabs/Tabs';
import { getRoyaltiesStudioMoviesRoute } from '../../../../../../../../../services/navigation/royalties-navigation/royalties-navigation-routes.service';
import { columnWidth, isWeb } from '../../../../../../../../../services/util-service/util.service';

class RoyaltiesByStudio extends RoyaltiesDisplayBaseAbstract {
    state = {
        data: [],
        dataLoaded: false,
        loadingMsg: 'Loading...',
        pagination: {}
    };

    loadData = response => {
        const { by_studio, pagination } = response.data.data;
        const { current_page, last_page, total, per_page } = pagination;

        this.setState({
            data: by_studio || [],
            loadingMsg: '',
            pagination: {
                page: current_page,
                pageCount: last_page,
                perPage: per_page,
                recordCount: total
            },
            dataLoaded: true
        });
    }

    onPageChange = (page) => this.getData(page);

    getData = (page) => {
        const date = this.getDates();
        this.setState({
            loadingMsg: 'Loading studios data'
        });
        getRoyaltiesByStudio(date.start_date, date.end_date, page)
            .then(this.loadData.bind(this))
            .then(() => this.renderModal())
            .catch((err = {}) => {
                displayErrorNotification({
                    duration: 6,
                    message: err.message,
                    description: err.response?.data?.error || err.response?.message
                });
                this.setState({
                    dataLoaded: true,
                    loadingMsg: ''
                });
                console.log(err);
            });
    };

    componentDidMount() {
        this.getData(1);
    };

    compensateForValueIfMissing = (value, valueText) => {
        return value ? valueText : '';
    };

    getColumns = () => {
        const { t } = this.props;

        return [{
            dataIndex: 'studio.name',
            title: 'Name',
            width: columnWidth(300)
        }, {
            dataIndex: 'by_movie',
            title: 'Movies #',
            render: this.renderMoviesLink.bind(this),
            width: columnWidth(300)
        }, {
            dataIndex: 'view_time',
            title: t('PayoutsByMonth.viewTime'),
            render: this.renderTime,
            width: columnWidth(300)
        }, {
            dataIndex: 'payout',
            title: t('PayoutsByMonth.payout'),
            render: this.renderNumber,
            align: 'right',
            width: columnWidth(200)
        }];
    };

    getReductionHeight = () => 190;

    getRowKey = (item) => item.studio.id;

    renderTime = time => {
        const { t } = this.props;
        const days = Math.floor(time / (60 * 60 * 24));
        const hours = Math.floor((time / (60 * 60)) % 24);
        const minutes = Math.floor((time / 60) % 60);

        const daysText = `${days} ${t('PayoutsByMonth.days')}`;
        const hoursText = `${hours} ${t('PayoutsByMonth.hours')}`;
        const minutesText = `${minutes} ${t('PayoutsByMonth.minutes')}`;

        return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
    };

    renderMoviesLink = (item = [], data = {},) => {
        const id = data.studio?.id;
        const name = data.studio?.name;
        const { year, month } = this.props;
        const label = item.length === 1 ? 'movie' : 'movies';
        const totalMovies = item.length !== 0 ? `${item.length} ${label}` : '';

        return (
            <Link
                to={getRoyaltiesStudioMoviesRoute(id)}
                className='MoviesCount'
                state={{ year, month, id, name }}
            >
                {totalMovies}
            </Link>
        )
    }

    onExportStudioSuccess = response => {
        this.setState({
            loadingMsg: ''
        });
        displaySuccessNotification({
            duration: 0,
            message: 'Export successfull!',
            description: (
                <CSVLink
                    data={response.data}
                    className="btn btn-primary"
                    target="_blank"
                    filename={"royalties-report-studios.csv"}
                >Download Studios Report</CSVLink>
            )
        });
    };

    getDates = () => {
        const { year, month } = this.props;
        const rangeStart = moment(`${year}/${month}`, 'YYYY/M').startOf('month');
        const rangeEnd = moment(`${year}/${month}`, 'YYYY/M').endOf('month');
        return {
            start_date: rangeStart.format(momentFormats.dateYearFirst),
            end_date: rangeEnd.format(momentFormats.dateYearFirst)
        };
    };

    downloadCsvFile = () => {
        const date = this.getDates();
        this.setState({
            loadingMsg: 'Export in progress'
        });
        exportRoyaltiesByStudio(date.start_date, date.end_date)
            .then(this.onExportStudioSuccess)
            .catch(err => {
                console.log(err);
                this.setState({
                    loadingMsg: ''
                });
                displayErrorNotification({
                    duration: 3,
                    message: 'Export failed!'
                });
            })
    };

    renderFooter = () => {
        let view = null;
        const { page, perPage, recordCount, pageCount } = this.state.pagination;
        view = (
            <BaseFooter
                className='Footer'
                currentPage={page}
                onChange={this.onPageChange}
                pageCount={pageCount}
                pageSize={perPage}
                recordCount={recordCount}
            >
                <Button onClick={() => this.downloadCsvFile()}>Export</Button>
            </BaseFooter>
        );
        return view;
    };

    getRoutes = () => {
        const date = this.getDates();
        const routes = [{
            url: `/${ROUTES.MAIN_DASHBOARD}`,
            text: 'payouts by month',
        }, {
            text: `payouts by studios (${renderMoment(date.start_date)} - ${renderMoment(date.end_date)})`,
        }];
        return routes;
    };

    renderTabs = () => {
        const { year, month } = this.props;
        return <Tabs year={year} month={month} active='studios' />;
    };

    renderModal = () => {
        return (
            Modal.info({
                title: (
                    <div style={{ fontSize: '1.1em' }}>
                        <p>Royalties by Studios results are for reference only. Since movies can be assigned to more than one studio, the times and payout amounts may overlap and not accurately reflect the final amounts.</p>

                        <p>To view accurate payout amounts, please look at the Licensor Totals.</p>
                    </div>
                ),
                width: isWeb() ? '70%' : '100%'
            })
        )
    };

    render() {
        const { dataLoaded, loadingMsg, data } = this.state;

        return (
            <LoadingWrapper
                dataLoaded={dataLoaded}
                isLoading={!!loadingMsg}
                loadingMsg={loadingMsg}
            >
                {this.renderTabs()}
                <BaseMultipleRouteNavigationItem routes={this.getRoutes()} />
                {this.renderTable(data)}
                {this.renderFooter()}
            </LoadingWrapper>
        );
    };
}

export default withTranslation()(RoyaltiesByStudio);