import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Layout, Icon } from "antd";

import AvatarDropdown from "./AvatarDropdown/AvatarDropdown";

import { MainDashboardComController } from "../../main-dashboard-comm.controller";

import './MainDashboardHeader.scss';
import { MenuItems } from '../../MenuItemsEnum';
import { ModalController } from '../../../../controllers/modal-controller/modal.controller';
import ConfirmationModal from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import Company from './Company/Company';
import { navigate } from '@reach/router';
import { ROUTES } from '../../../../routes';

/* istanbul ignore file */
class MainDashboardHeader extends Component {

  toggleSideMenu = () => {
    MainDashboardComController.toggleSideMenu();
  };

  /**
     * Displays logout confirm modal
     */
  confirmLogout = () => {
    const { t, logout } = this.props;
    const modal = (
      <ConfirmationModal
        confirm={logout}
        title={t('MainDashboard.confirmLogout')}
        message={t('MainDashboard.confirmLogoutMessage')} />
    );
    ModalController.showModal(modal);
  };

  handleRouteSwitch = (id) => {
    switch (id) {
      case MenuItems.LOGOUT:
        this.confirmLogout();
        break;
      default:
        break;
    }
  };

  handleClick = (event) => {
    const id = event.target.id;
    if (id) {
      this.handleRouteSwitch(id);
    }
  };

  onClick = () => {
    navigate(`/${ROUTES.MAIN_DASHBOARD}`);
  };

  render() {
    // const { navigationItems, onLogoClick, onClick } = this.props;

    return (
      <Layout.Header className="MainDashboardHeader header">
        <div className="MainDashboardHeader-brand-section">
          <div className="MainDashboardHeader-firstChild">
            <Icon type="menu"
              onClick={this.toggleSideMenu} />
            <Company onClick={this.onClick} />
          </div>
          <AvatarDropdown onClick={this.handleClick} />
        </div>
        {/* <BaseHorizontalNavigation items={navigationItems} mainNavigation={true}>
          {this.getAdditionalActions()}
        </BaseHorizontalNavigation> */}
      </Layout.Header>
    );
  }
}

// MainDashboardHeader.propTypes = {

// };

export default withTranslation()(MainDashboardHeader);
