import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import BaseMultipleRouteNavigationItem from '../../../../../../../../../components/BaseMultipleRouteNavigationItem/BaseMultipleRouteNavigationItem';

import BaseFooter from "../../../../../../../../../components/BaseFooter/BaseFooter";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import RoyaltiesDisplayBaseAbstract from "../RoyaltiesDisplayBaseAbstract/RoyaltiesDisplayBaseAbstract";

import {
    momentFormats,
    renderMoment
} from '../../../../../../../../../services/date-service/date.service';
import {
    getRoyaltiesStudiosPeriodRoute
} from '../../../../../../../../../services/navigation/royalties-navigation/royalties-navigation-routes.service';
import { ROUTES } from '../../../../../../../../../routes';
import { getRoyaltiesByMovie } from '../../../../../../../../../services/royalties-service/royalties.service';
import { columnWidth } from '../../../../../../../../../services/util-service/util.service';
import { displayErrorNotification } from '../../../../../../../../../services/notification-service/notification.service';

class RoyaltiesStudioMovies extends RoyaltiesDisplayBaseAbstract {
    state = {
        data: [],
        dataLoaded: false,
        loadingMsg: 'Loading...',
        pagination: {}
    };

    loadMovieData = response => {
        const { by_movie, pagination } = response.data.data;
        const { current_page, last_page, total, per_page } = pagination;
        this.setState({
            data: by_movie || [],
            loadingMsg: '',
            pagination: {
                page: current_page,
                pageCount: last_page,
                perPage: per_page,
                recordCount: total
            },
            dataLoaded: true
        });
    }

    onPageChange = (page) => this.getData(page);

    getData = (page) => {
        const date = this.getDates();
        const id = this.props.id;
        this.setState({
            loadingMsg: 'Loading studio movies data'
        });
        getRoyaltiesByMovie(page, {
            studios_id: id,
            start_date: date.start_date,
            end_date: date.end_date
        })
            .then(this.loadMovieData.bind(this))
            .catch((err = {}) => {
                displayErrorNotification({
                    duration: 6,
                    message: err.message,
                    description: err.response?.data?.error || err.response?.message
                });
                this.setState({
                    dataLoaded: true,
                    loadingMsg: ''
                });
                console.log(err);
            });
    };

    componentDidMount() {
        this.getData(1);
    };

    compensateForValueIfMissing = (value, valueText) => {
        return value ? valueText : '';
    };

    getColumns = () => {
        const { t } = this.props;

        return [{
            dataIndex: 'movie.title',
            title: 'Title',
            width: columnWidth(300)
        }, {
            dataIndex: 'view_time',
            title: t('PayoutsByMonth.viewTime'),
            render: this.renderTime,
            width: columnWidth(300)
        }, {
            dataIndex: 'payout',
            title: t('PayoutsByMonth.payout'),
            render: this.renderNumber,
            align: 'right',
            width: columnWidth(200)
        }];
    };

    getReductionHeight = () => 150;

    getRowKey = (item) => item.movie.id;

    renderTime = time => {
        const { t } = this.props;
        const days = Math.floor(time / (60 * 60 * 24));
        const hours = Math.floor((time / (60 * 60)) % 24);
        const minutes = Math.floor((time / 60) % 60);

        const daysText = `${days} ${t('PayoutsByMonth.days')}`;
        const hoursText = `${hours} ${t('PayoutsByMonth.hours')}`;
        const minutesText = `${minutes} ${t('PayoutsByMonth.minutes')}`;

        return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
    };

    getDates = () => {
        const { year, month, name } = this.props.location.state;
        const rangeStart = moment(`${year}/${month}`, 'YYYY/M').startOf('month');
        const rangeEnd = moment(`${year}/${month}`, 'YYYY/M').endOf('month');
        return {
            start_date: rangeStart.format(momentFormats.dateYearFirst),
            end_date: rangeEnd.format(momentFormats.dateYearFirst),
            year,
            month,
            name
        };
    };

    renderFooter = () => {
        let view = null;
        const { page, perPage, recordCount, pageCount } = this.state.pagination;
        view = (
            <BaseFooter
                className='Footer'
                currentPage={page}
                onChange={this.onPageChange}
                pageCount={pageCount}
                pageSize={perPage}
                recordCount={recordCount}
            >
            </BaseFooter>
        );
        return view;
    };

    getRoutes = () => {
        const date = this.getDates();
        const byStudioLink = getRoyaltiesStudiosPeriodRoute(date.year, date.month);
        const routes = [
            {
                url: `/${ROUTES.MAIN_DASHBOARD}`,
                text: 'payouts by month'
            },
            {
                url: byStudioLink,
                text: `payouts by studio (${renderMoment(date.start_date)} - ${renderMoment(date.end_date)})`,
            },
            {
                text: `${date.name} movies`,
            }
        ];

        return routes;
    };

    render() {
        const { dataLoaded, loadingMsg, data } = this.state;

        return (
            <LoadingWrapper
                dataLoaded={dataLoaded}
                isLoading={!!loadingMsg}
                loadingMsg={loadingMsg}
            >
                <BaseMultipleRouteNavigationItem routes={this.getRoutes()} />
                {this.renderTable(data)}
                {this.renderFooter()}
            </LoadingWrapper>
        );
    };
}

export default withTranslation()(RoyaltiesStudioMovies);