import React, {Component} from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';

import {ModalController} from "../../../controllers/modal-controller/modal.controller";

import './ModalDialog.scss';
import {Card, Icon} from "antd";

import './ModalDialog.scss';

import LoadingWrapper from "../../LoadingWrapper/LoadingWrapper";

export class ModalDialog extends Component {

  initialSize;

  state = {
    loadingMessage: '',
    isMaximized: false
  };

  componentDidMount() {
    if (!this.initialSize) {
      const node = ReactDOM.findDOMNode(this);
      this.initialSize = {
        height: node.offsetHeight,
        width: node.offsetWidth
      };
    }
  }

  clearLoading = () => {
    this.setState({
      loadingMessage: ''
    });
  };

  closeModal = () => {
    ModalController.removeModal();
  };

  getMaximizeElement = () => {
    const maximizeIcon = this.state.isMaximized ? 'shrink' : 'arrows-alt';
    return (
      <Icon type={maximizeIcon} onClick={this.toggleMaximize.bind(this)}/>
    );
  };

  getModalClasses = () => {
    const {className} = this.props;
    const classes = ['MainModalDialog'];
    if (className) {
      classes.splice(0, 0, className);
    }
    if (this.state.isMaximized) {
      classes.push('Maximized');
    }

    return classes;
  };

  getModalFooter = () => {
    let footer = null;
    const {actions} = this.props;
    if (actions) {
      footer = (
        <div className="ModalDialogFooter">
          {actions}
        </div>
      );
    }

    return footer;
  };

  getModalHeader = () => {
    let header = null;
    const {title} = this.props;
    if (title) {
      header = (
        <div className="ModalDialogHeader">
          {this.getModalTitle()}
        </div>
      );
    }

    return header;
  };

  getModalTitle = () => {
    const maximize = this.props.showMaximize ? this.getMaximizeElement() : null;
    return (
      <div className="ModalDialogTitle">
        {this.props.title}
        <div className="ModalDialogTitle-actions">
          {maximize}
          <Icon type="close" onClick={this.onCloseModal}/>
        </div>
      </div>
    );
  };

  getStyle = () => {
    let style = null;
    if (this.props.showMaximize) {
      style = this.initialSize;
      if (this.state.isMaximized) {
        style = {
          width: '100vw',
          height: '100vh'
        };
      }
    }

    return style;
  };

  onCloseModal = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    } else {
      this.closeModal();
    }
  };

  setLoading = (message) => {
    this.setState({
      loadingMessage: message
    });
  };

  toggleMaximize = () => {
    this.setState(prevState => {
      return {
        isMaximized: !prevState.isMaximized
      };
    });
  };

  render() {
    const classes = this.getModalClasses();
    const {loadingMessage} = this.state;
    return (
      <LoadingWrapper className={classes.join(' ')}
                      style={this.getStyle()}
                      dataLoaded={true}
                      isLoading={!!loadingMessage}
                      loadingMsg={loadingMessage}>
        {this.getModalHeader()}
        <Card>
          <div className="ModalDialogBody">
            {this.props.children}
          </div>
          {this.getModalFooter()}
        </Card>
      </LoadingWrapper>
    );
  }
}

ModalDialog.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]),
  className: PropTypes.string,
  forwardedRef: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  showMaximize: PropTypes.bool
};

export default ModalDialog;
