import React, {Component} from 'react';
import {Pagination} from "antd";
import PropTypes from 'prop-types';

import PageInput from "../PageInput/PageInput";

import './PageNavigation.scss';

class PageNavigation extends Component {

  /**
   * Returns PageInput element for render
   *
   * @returns {PageInput}
   */
  renderPageInput = () => {
    const {currentPage, onChange, pageCount} = this.props;
    return pageCount > 1 ? (
      <PageInput currentPage={currentPage}
                 pageCount={pageCount}
                 onChange={onChange}/>
    ) : null;
  };

  /**
   * Returns Pagination element for render
   *
   * @returns {Pagination}
   */
  renderPagination = () => {
    const {currentPage, onChange, pageSize, recordCount} = this.props;
    return (
      <Pagination current={currentPage}
                  total={recordCount}
                  hideOnSinglePage
                  showQuickJumper
                  pageSize={pageSize}
                  onChange={onChange}/>
    );
  };

  render() {
    return (
      <div className="PageNavigation">
        {this.renderPagination()}
        {this.renderPageInput()}
      </div>
    );
  }
}

PageNavigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired
};

export default PageNavigation;
