import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageNavigation from "../PageNavigation/PageNavigation";

import './BaseFooter.scss';

class BaseFooter extends Component {

  renderChildren = () => {
    const { children } = this.props;
    return children ? (
      <div>
        {children}
      </div>
    ) : null;
  };

  renderPagination = () => {
    const { pageCount } = this.props;
    return pageCount > 1 ? <PageNavigation {...this.props} /> : null;
  };

  render() {
    const { children, className, pageCount } = this.props;
    const classes = children ? ['BaseFooter', className] : ['BaseFooter', className, 'NavigationOnly'];
    return children || pageCount > 1 ? (
      <div className={classes.join(' ')}>
        {this.renderChildren()}
        {this.renderPagination()}
      </div>
    ) : null;
  }
}

BaseFooter.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  recordCount: PropTypes.number
};

export default BaseFooter;
