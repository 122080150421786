import axios from 'axios';
import moment from 'moment';

export const urls = {
  users: '/royalties/users',
  user: '/royalties/users/',
  monthly: '/royalties/report/profitloss/monthly',
  monthlyExport: '/royalties/report/profitloss/monthly/export',
  movies: '/royalties/report/profitloss/movies',
  moviesExport: '/royalties/report/profitloss/movies/export',
  studios: '/royalties/report/profitloss/studios',
  studiosExport: '/royalties/report/profitloss/studios/export'
};

/**
 * Executes get nats filtered users http request
 *
 * @param {number} page
 * @param {String[]} filters
 * @returns {Promise<AxiosResponse<T>>}
 */
// export const getFilteredNatsUsers = (page, filters) => {
//   return axios.get(`${urls.users}?${filters.join('&')}&page=${page}`);
// };

/**
 * Executes get nats users http request
 *
 * @param {number} page
 * @param {String[]} filters
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
// export const getNatsUsers = (page, filters) => {
//   return filters.length ? getFilteredNatsUsers(page, filters) : getNatsUsersPage(page);
// };

export const getRoyaltiesUsers = (page, filters) => {
  return getRoyaltiesUsersPage(page);
};


/**
 * Executes get nats users page http request
 *
 * @param {number} page
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRoyaltiesUsersPage = (page) => {
  return axios.get(`${urls.users}?page=${page}`);
};

export const getRoyaltiesUserById = (id) => {
  return axios.get(`${urls.user}${id}`);
}

export const getRoyaltiesReportMonthly = (start_date, end_date, page = 1) => {
  return axios.get(urls.monthly, {
    params: {
      start_date: start_date || moment().clone().startOf('month').format('Y-MM-DD'),
      end_date: end_date || moment().clone().endOf('month').format('Y-MM-DD'),
      page: "" + page
    }
  });
}

export const exportRoyaltiesByMonth = (start_date, end_date, page) => {
  return axios.get(`${urls.monthlyExport}`, {
    params: {
      start_date,
      end_date
    }
  });
}

export const getRoyaltiesByMovie = (page, filters) => {
  const params = { page };

  if (filters.start_date) {
    params.start_date = filters.start_date;
  }

  if (filters.end_date) {
    params.end_date = filters.end_date;
  }

  if (filters.studios_id) {
    params.studios_id = filters.studios_id;
  }

  return axios.get(urls.movies, { params });
}

export const exportRoyaltiesByMovie = (start_date, end_date) => {
  return axios.get(`${urls.moviesExport}`, {
    params: {
      start_date,
      end_date
    }
  });
}

export const getRoyaltiesByStudio = (start_date, end_date, page) => {
  return axios.get(urls.studios, {
    params: {
      page,
      start_date,
      end_date
    }
  });
};

export const exportRoyaltiesByStudio = (start_date, end_date) => {
  return axios.get(`${urls.studiosExport}`, {
    params: {
      start_date,
      end_date
    }
  });
};
