import React from 'react';
import LinkButton from '../../../../../../../../components/LinkButton/LinkButton';
import {
    getRoyaltiesMoviesPeriodRoute,
    getRoyaltiesStudiosPeriodRoute
} from '../../../../../../../../services/navigation/royalties-navigation/royalties-navigation-routes.service';
import './Tabs.scss';

const MOVIES = 'movies';
const STUDIOS = 'studios';

const Tabs = ({ year, month, active = '' }) => {
    const activeTab = active === MOVIES ? MOVIES : STUDIOS;

    return (
        <div className='Tabs'>
            <LinkButton
                href={getRoyaltiesMoviesPeriodRoute(year, month)}
                className={activeTab === MOVIES ? 'active' : ''}
            >
                By Movies
            </LinkButton>
            <LinkButton
                href={getRoyaltiesStudiosPeriodRoute(year, month)}
                className={activeTab === STUDIOS ? 'active' : ''}
            >
                By Studios
            </LinkButton>
        </div>
    );
};

export default Tabs;