import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Dropdown, Icon, Menu} from "antd";
import {MenuItems} from "../../../MenuItemsEnum";
import {useTranslation} from "react-i18next";

import {getUserLocalData} from "../../../../../services/users-service/users.service";

import './AvatarDropdown.scss';

/**
 * Returns username from localStorage
 *
 * @returns {String|undefined}
 */
export const getUsername = () => {
  return getUserLocalData().name;
};

const AvatarDropdown = props => {
  const {t} = useTranslation();
  const menu = (
    <Menu className="MainDashboardHeader-AvatarDropdownMenu">
      {/* <Menu.Item>
        <div id={MenuItems.ABOUT} onClick={props.onClick}>
          <Icon type="info-circle"/>
          {t('AvatarDropdown.about')}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div id={MenuItems.SETTINGS} onClick={props.onClick}>
          <Icon type="setting"/>
          {t('AvatarDropdown.settings')}
        </div>
      </Menu.Item> */}
      <Menu.Item>
        <div id={MenuItems.LOGOUT} onClick={props.onClick}>
          <Icon type="logout"/>
          {t('AvatarDropdown.logout')}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['hover']} className="MainDashboardHeader-AvatarDropdown" placement="bottomRight">
      <div className="ant-dropdown-link">
        <Avatar icon="user"/>
        <div className="UserName">{getUsername()}</div>
        <Icon type="down"/>
      </div>
    </Dropdown>
  );
};

AvatarDropdown.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AvatarDropdown;
