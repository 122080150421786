import React, { Component } from 'react';
import { Router } from "@reach/router";
import { withTranslation } from "react-i18next";
import RoyaltiesByMonth from './RoyaltiesByMonth/RoyaltiesByMonth';
import RoyaltiesByMovie from './RoyaltiesByMovie/RoyaltiesByMovie';
import RoyaltiesByStudio from './RoyaltiesByStudio/RoyaltiesByStudio';
import RoyaltiesStudioMovies from './RoyaltiesStudioMovies/RoyaltiesStudioMovies';

import './RoyaltiesView.scss';
class RoyaltiesView extends Component {

  render() {
    const { contentHeight } = this.props;
    return (
      <div className="RoyaltiesView">
        <Router>
          <RoyaltiesByMonth
            path={'/'}
            contentHeight={contentHeight}
          />
          <RoyaltiesByMovie
            path={'/royalties/movies/period/:year/:month'}
            contentHeight={contentHeight}
          />
          <RoyaltiesByStudio
            path={'/royalties/studios/period/:year/:month'}
            contentHeight={contentHeight}
          />
          <RoyaltiesStudioMovies
            path={'/royalties/studio/:id/movies'}
            contentHeight={contentHeight}
          />
        </Router>
      </div>
    );
  }
}

export default withTranslation()(RoyaltiesView);
